(function() {
	var drawPopupForLinkDetails, drawPopupForScreeningLinkDetails;
	$(function() {
		return prepareControls();
	});

	prepareControls = function() {
		$("#btnGetLinkWrapperModuleStyle").click(function() {
			return showScreeningLinkPopup();
		});
	};
 

	showScreeningLinkPopup = function() {
		return $.ajax({
			type : "GET",
			url : "/getUniversalScreeningLinks",
			processData : false,
			success : function(data, status, xhrObj) {
				return drawPopupForScreeningLinkDetails(data);
			}
		});
	};
	 
	drawPopupForScreeningLinkDetails = function(data) {
		var link, _i, _len, _ref;
			$('#getScreeningLinkUdel #linkBlock').html("");
			_ref = data.links;
			for (_i = 0, _len = _ref.length; _i < _len; _i++) {
				link = _ref[_i];
				$('#getScreeningLinkUdel #linkBlock').append("<div class='row'><div class='col-lg-10 col-lg-offset-1 centered'><span class='link'>Survey Title: </span></div></div>");
				$('#getScreeningLinkUdel #linkBlock').append("<div class='row'><div id='surveyTitle' class='col-lg-10 col-lg-offset-1 centered'><b>" + link.surveyTitle + "</b></div></div>");
				if(link.showDistrictView){
					$('#getScreeningLinkUdel #linkBlock').append("<div class='row'><div class='col-lg-10 col-lg-offset-1 centered'><span>Link for " + link.schoolName + "</span>");
				}
				$('#getScreeningLinkUdel #linkBlock').append("<div class='row'><div id='linkWrapper' class='col-lg-10 col-lg-offset-1 centered'><div><a id=link_"+link.token+" style='word-wrap: break-word;' target='_blank123' href='" + link.url + "'>" + link.url + "</a> ( <span><a class='copyLinkButton' id="+link.token+" onclick='copyToClipboard(this)'> <i class=\"clipboardIcon\">&#128203;</i> COPY TO CLIPBOARD </a>)</span></div></div></div><br><br>");
			}
			return $('#getScreeningLinkUdel').modal('show');
		}; 
}).call(this);

function copyToClipboard(element) {
	$(element).addClass("copied").html(" COPIED! ");
	setTimeout(function() {
		$(element).removeClass("copied").html("<i class=\"clipboardIcon\">&#128203;</i> COPY TO CLIPBOARD ");
	}, 2000);
	var id = element.id;
	var el = document.getElementById("link_" + id);
	var range = document.createRange();
	range.selectNodeContents(el);
	var sel = window.getSelection();
	sel.removeAllRanges();
	sel.addRange(range);
	document.execCommand('copy');
}

function setRequestHeaders(request) {
	request.setRequestHeader("s_id", sessionStorage.getItem("s_id"));
	request.setRequestHeader("srv_id", sessionStorage.getItem("srv_id"));
	request.setRequestHeader("unit_id", sessionStorage.getItem("unit_id"));
	request.setRequestHeader("curr_q_id", sessionStorage.getItem("curr_q_id"));
}

function processResponseHeaders(response) {
	sessionStorage.setItem("s_id", response.getResponseHeader("s_id"));
	sessionStorage.setItem("srv_id", response.getResponseHeader("srv_id"));
	sessionStorage.setItem("unit_id", response.getResponseHeader("unit_id"));
	sessionStorage.setItem("curr_q_id", response.getResponseHeader("curr_q_id"));
}

$.extend($.expr[":"], {
		"containsNC": function(elem, i, match, array) {
		return (elem.textContent || elem.innerText || "").toLowerCase().indexOf((match[3] || "").toLowerCase()) >= 0;
	}
});

function selectUnit(unitId) {
	$.get("/selectUnit/"+unitId, function( data ) {
		location.reload();
	});
	
}

function selectTag(tagId) {
	$.get("/selectTag/"+tagId, function( data ) {
		location.reload();
	});
	
}

function deselectTag() {
	$.get("/deselectTag", function( data ) {
		location.reload();
	});
	
}


function selectSurvey(surveyId) {
	$.get("/selectSurvey/"+surveyId, function( data ) {
		location.reload();
	});
	
}

function checkCookie(){
    var cookieEnabled = navigator.cookieEnabled;
    if (!cookieEnabled){ 
        document.cookie = "testcookie";
        cookieEnabled = document.cookie.indexOf("testcookie")!=-1;
    }
    return cookieEnabled || showCookieFail();
}

function showCookieFail(){
	sweetAlert("Oops...", "Seems like cookies are disabled in your browser. To take the survey, please turn them on and try again.", "error");
}

function deselectSurvey() {
	$.get("/deselectSurvey", function( data ) {
		location.reload();
	});
	
}

var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};

 /* process ALL Ajax Errors here, unless intercepted earlier */ 
$(document).ajaxError(function(event,xhr,options,exc){
	var errorString = "";
	var errorSymbol = "error";
	var errorHeader = "Oops...";
	if(xhr.status === 404){
        errorString = xhr.responseText;
        errorSymbol = "warning";
	} else if(xhr.status === 422){
		errorHeader = 'Input is required';
		errorString = xhr.responseText;
		errorSymbol = "warning";
	} else if(xhr.status === 400){
		errorString = "An error occured: "+xhr.responseText;
	} else if(xhr.status === 401 || xhr.status === 440) {
		$.post("/endSession");	
		sessionStorage.clear();
		Cookies.remove('s_id');
		Cookies.remove('srv_id');
		Cookies.remove('curr_q_id');
		Cookies.remove('unit_id');
		Cookies.remove('PLAY_SESSION');
		Cookies.remove('G_AUTHUSER_H');
		if(xhr.getResponseHeader("sessionExpired") == "true"){
			if(xhr.getResponseHeader("redirectUrl"))
				window.location.href = xhr.getResponseHeader("redirectUrl");
			else
				window.location.href = window.location.href;
			return;
		}else
			errorString = "You are not authorized to perform this. Please check your account settings";
	} else {
		errorString = "Something went wrong. To resolve this, first try to refresh the web page. If this does not work, please contact support at support@covitalityapp.com";
	}
	sweetAlert(errorHeader, errorString, errorSymbol);
});


define("main", function(){});

